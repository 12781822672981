<template>
  <div class="saleInvoiceDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.saleInvoice.id ? '编辑' : '新增' }}销售开票</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="!data.saleInvoice.id">保存</el-button>
          <el-button type="primary" @click="setById()" v-if="data.saleInvoice.id">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="开票日期">
        <el-date-picker style="width: 100%;" v-model="data.saleInvoice.invoiceDate" type="date" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select :disabled="data.saleInvoice.id" v-model="data.saleInvoice.custom" filterable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="开票煤矿数量">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleInvoice.invoiceQuantity" />
      </el-form-item>
      <el-form-item label="发票金额">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleInvoice.invoiceAmount" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  customList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "saleInvoiceDetail")
const data = reactive({
  saleInvoice: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {}
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.saleInvoice))
  param.invoiceDate = util.parseTime(param.invoiceDate, '{y}-{m}-{d}')
  api.post('/backend/saleInvoice/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = () => {
  const param = {}
  param.id = data.saleInvoice.id
  param.invoiceDate = util.parseTime(data.saleInvoice.invoiceDate, '{y}-{m}-{d}')
  param.invoiceQuantity = data.saleInvoice.invoiceQuantity
  param.invoiceAmount = data.saleInvoice.invoiceAmount

  api.post('/backend/saleInvoice/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.saleInvoiceDetail {
  margin: auto;
  max-width: 900px;
}
</style>